<template>
  <div class="page">
    <el-link type="primary" :underline="false" @click="printRows">批量打印</el-link>
<!--    <el-link type="primary" :underline="false" @click="test">test</el-link>-->
    <el-table :data="orderList" ref="multipleTable" @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column label="订单号码" prop="order_sn"/>
      <el-table-column label="订单状态" prop="order_state_text"/>
      <el-table-column label="物品清单" prop="goods_name" :show-overflow-tooltip="true"/>
      <el-table-column label="历史打印次数" prop="print_times"/>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-link type="primary" :underline="false" @click="printRow(scope.row.order_id)">打印</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex;justify-content: flex-end;margin-top: 10px;">
      <el-pagination layout="prev, pager, next" :total="pageCount" :current-page.sync="search.page" hide-on-single-page="false" @current-change="pageChangeHandle"/>
    </div>
  </div>
</template>

<script>
import {apiPrintOrder, apiWaitPrintOrder} from "@/request/api"
import VabPrint from '@/extra/VabPrint'
export default {
  name: "distributionList",
  data(){
    return{
      pageCount: 0,
      search: {},
      orderList:[],
      multipleSelection:[],
      printHtml:''
    }
  },
  created() {
    this.initSearch()
    this.getWaitingPrint()
  },
  methods: {
    printRows(){
      if(this.multipleSelection.length > 0){
        var id = []
        this.multipleSelection.forEach(item => {
          id.push(item.order_id)
        })
        apiPrintOrder({id}).then(res =>{
          if(res.code == 200){
            this.printHtml = ''
            res.data.forEach(item => {
              this.printHtml = this.printHtml + item.html
            })
            VabPrint(this.printHtml, { noPrintParent: true })
          }else{
            this.$message.error(res.message)
          }
        })
      }else{
        this.$message.error('请选择需要打印的配货单')
      }

    },
    printRow(id){
      apiPrintOrder({id:[id]}).then(res =>{
          if(res.code == 200){
            this.printHtml = ''
            res.data.forEach(item => {
              this.printHtml = this.printHtml + item.html
            })
            VabPrint(this.printHtml, { noPrintParent: true })
          }else{
            this.$message.error(res.message)
          }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    pageChangeHandle(){
      this.getWaitingPrint()
      window.screenTop = 0;
    },
    initSearch(){
      this.search = {
        page: 1,
        'per-page': 10,
      }
    },
    getWaitingPrint(){
      apiWaitPrintOrder(this.search).then(res => {
        if(res.code == 200){
          this.pageCount = res.data.count
          this.orderList = res.data.list.order_list
        }else{
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
}
.printView {
  height: 0;width: 0;overflow: hidden
}
</style>
